<template>
  <div>
    <h3 ref="def">
      System of Linear Equations
    </h3>
    <p>
      A system means a set, a group or a family of two or more objects.
      A linear equation is an equation that is linear in its constituent variable/s &mdash; in simple words, it means that the highest order of any of the constituent variables cannot be greater than unity.  For example: \(f(x) = 3 x + 4\) is an example of a linear equation in \(x\) - compare this to \(g(x) = 4 x^2 + 3 x + 9\) which is a quadratic function in \(x\).
      Thus, a system of linear equations simply means a set of equations that are linear in the constituent variables. For example, the general form of a system of linear equations in two variables x and y is given as:
      $$ m_1 x + n_1 y = l_1$$
      $$ m_2 x + n_2 y = l_2$$
      where \(m_1 \), \(n_1\), \(m_2\), \(n_2\), \(l_1\) and \(l_2\) all are real numbers.<br>
      Graphically, each linear equation in two variables (say \(x\) and \(y\)) represents a straight line in the \(x-y\) plane.
      Thus, the solution of a system of linear equations in two variables essentially represents the point of intersection of the two lines.
      <br>
    </p>
    <h3 ref="sol">
      Condition for Existence of a Solution
    </h3>
    <p>
      The necessary condition for existence of a solution is that the number of equations must be same as the number of variables. Note that this is just a necessary condition - meaning it must be met whenever a solution exists but it alone is not sufficient to guarantee the existence of a solution.  A system of linear equations can have one, infinitely many or no solution. A system of equations with no solution is called "inconsistent" while a system with one or infinitely many solution is called "consistent".
      <ul style="list-style-type:square;">
        <li>
          <h6> Exactly One Solution </h6>
          The system has exactly one solution if:
          $$ \frac{m_1}{m_2} \ne \frac{n_1}{n_2} $$
          Graphically, this means that the two lines described by the two equations intersect exactly at one point.
        </li>
        <li>
          <h6> Infinitely Many Solutions </h6>
          The system has infinitely many solutions if:
          $$ \frac{m_1}{m_2} = \frac{n_1}{n_2} $$ and $$l_1 = l_2$$
          Graphically, this means that the two lines lie on top of each other (overlap).
        </li>
        <li>
          <h6> No Solution </h6>
          The system has no solutions if:
          $$ \frac{m_1}{m_2} = \frac{n_1}{n_2} $$ and $$l_1 \ne l_2$$
          Graphically, this happens when the two lines are parallel to each other (but have no overlap).
        </li>
      </ul>
    </p>
    <h3 ref="method">
      How do we solve a system of linear equations?
    </h3>
    <p> There are several different ways of solving a system of linear equations such as substitution method, elimination method, graphical method.  These methods are described in below. </p>
    <ul style="list-style-type:square;">
      <li>
        <h6 ref="sub">
          Substitution Method
        </h6>
      </li>
      <p>
        Let's consider the following system of linear equations in two variables (\(x\) and \(y\)):
        $$ 3 x + 4 y = 5 \tag{1}$$
        $$ 4 x + 8 y = 8 \tag{2}$$
        First, we solve one of the linear equations in the system (equation # 1) to obtain the expression for one variable (say \(y\)) in terms of the other variable (say \(x\)).
        $$ y = \frac{5}{4} - \frac{3}{4} x \tag{3}$$
        Then, we substitute for \(y\) in terms of \(x\) in equation #1 using the above expression. Thus, we obtain an equation which is entirely in \(x\).
        $$ 4 x + 8 \left(\frac{5}{4} - \frac{3}{4} x \right) = 8 $$ $$ \Rightarrow 4 x + 10 - 6 x = 8$$
        $$\Rightarrow 2 x = 2$$
        Solve the resulting equation (equation # 3) for \(x\):
        $$ x =1$$
        Substitute the value of \(x\) in the expression for \(y\) (equation # 3) to obtain the value \(y\)
        $$y = \frac{5}{4} - \frac{3}{4}  = \frac{1}{2}$$
      </p>
      <li>
        <h6 ref="graph">
          Graphical Method
        </h6>
      </li>
      <p> Graphical method involves plotting the lines described by each of the equations on a graph. Then, the solution corresponds to the point at which the two lines intersect. </p>
      <br>
    </ul>
    <h3 ref="graph">
      MagicGraph | Graphical Method of Solving a System of Linear Equations
    </h3>
    <p> This MagicGraph offers an interactive way to learn solving a system of linear equations using the graphical method. </p>
    <h5>To Get Started </h5>
    <p>
      Start by entering the coefficients of two equations of the system. The MagicGraph automatically draws the lines corresponding to the
      two equations and finds the point of intersection. This intersection represents the solution of the system of equations.
    </p>
    <!--<v-layout justify-left>
  <v-flex v-for="topic in topics"
          :key="topic.key"
          xs12
          sm6
  >
    <subtopic-card
      :headline="topic.headline"
      :description="topic.description"
      :img="topic.img"
      :path="topic.path"
    />
  </v-flex>
</v-layout> -->
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
//import SubtopicCard from '../../../views/EdfromEdliy.vue'
export default {
  name: 'LinearEquation',
  components: {
    SubtopicCard
  },
  data () {
    return {
      style: {layout: "margin: 10px"},
      topics: [
      {
        headline: "Ed from Edliy", description: 'Hi! I am Eve from Edliy. This playgraph will help to find the solution of a system of linear equations in two variables. Begin with entering the coefficients of the two equations that you want to solve. The playgraph will automatically plot those equations and find their point of intersection. This point of intersection represents the solution. ',
        img:"assets/teacher.svg", path: "/mathematics/sle"
      },
      ]
    }
  },
  created: function () {
    // Store mutations
    this.$store.commit('navigation/resetState');
    let title = 'System of Linear Equations';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'System of Linear Equation',img:'/assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Criterion for Existence of a Solution', img:'/assets/number-2.svg', action: () => this.goto('sol')},
      {title: 'Solving a System of Linear Equations', img:'/assets/number-3.svg', action: () => this.goto('method')},
      {title: 'Substitution Method', img:'/assets/number-4.svg', action: () => this.goto('sub')},
      {title: 'MagicGraph: Graphical Method',img:'/assets/touch.svg', action: () => this.goto('graph')}
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'System of Linear Equations',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to solve a system of linear equations.'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
