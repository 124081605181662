const Boxes = {
  box1: function () {
      var brd4 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 10, 10, -6],keepaspectratio:true, axis:true, ticks:false, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
      //
      var resize = function () {
            brd4.resizeContainer(brd4.containerObj.clientWidth, brd4.containerObj.clientHeight, true);
            brd4.fullUpdate();
            };
      window.onresize = resize;
      brd4.create('text',[0, 8.8, '<b>Linear Equation in Two Variables</b> '],{anchorX:'middle', cssStyle:'fontFamily:Oswald;', fontSize:function(){return Math.round(32*brd4.canvasWidth/800.)}, fixed:true});
      brd4.create('text',[0, 7.8, '(Hover over the point of intersection to see the solution)'],{anchorX:'middle', cssStyle:'fontFamily:Oswald;', fontSize:function(){return Math.round(22*brd4.canvasWidth/800.)}, fixed:true});

      // Equation#1
      var eqn1 = brd4.create('text',[-8, 3.75, 'Equation#1: a x \ + \ b y \ =  \ c'],{cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)}, parse:true,fixed:true});
      var a1txt = brd4.create('text',[-9, 2.5, 'a = '],{cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)},fixed:true});
      var b1txt = brd4.create('text',[-9, 1.25, 'b = '],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)}, fixed:true});
      var c1txt = brd4.create('text',[-9, -0., 'c = ' ],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)},fixed:true});
      var a1 = brd4.create('input', [ -8.0, 2.5, 3.0, ' '], {cssStyle: 'fontFamily:Oswald;width:5%;background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)}, fixed:true});
      var b1 = brd4.create('input', [ -8.0, 1.25, 4.0, ' '], {cssStyle:'fontFamily:Oswald;width:5%;background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)},fixed:true});
      var c1 = brd4.create('input', [ -8.0, 0., 5.0,  ' '], {cssStyle:'fontFamily:Oswald;width:5%;background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)},fixed:true});
      // Equation#2
      var a2txt = brd4.create('text',[-9, -2.5, 'm = '],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)}, parse:true,fixed:true});
      var b2txt = brd4.create('text',[-9, -3.75, 'n = '],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)}, parse:true,fixed:true});
      var c2txt = brd4.create('text',[-9, -5., 'l = '],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)}, parse:true,fixed:true});
      var eqn2 = brd4.create('text',[-8.0, -1.35, 'Equation#2: m x + n y = l'],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)}, parse:true});
      var a2 = brd4.create('input', [-8.0, -2.5, 4.0, ' ' ], {cssStyle:'fontFamily:Oswald;width:5%;background-color:#f44336;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)}, fixed:true});
      var b2 = brd4.create('input', [-8.0, -3.75, 8.0, ' ' ], {cssStyle:'fontFamily:Oswald; width:5%;background-color:#f44336;border: 1px solid black;border-radius: 3.5px;',
	  fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)},fixed:true});
      var c2 = brd4.create('input', [-8.0, -5.,  8.0, ' '], {cssStyle: 'fontFamily:Oswald; width:5%;background-color:#f44336;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)},fixed:true});
      var graph1 = brd4.create('functiongraph', [function(x){return (-a1.Value()/b1.Value())*x+c1.Value()/b1.Value();}, -10, 10], {name:'Line: Equation#1', withLabel:true, strokeColor:'#008CBA', strokeWidth:4});
      graph1.label.setAttribute({visible:false});
      graph1.on('over', function () {this.label.setAttribute({visible:true});});
      graph1.on('out', function () {this.label.setAttribute({visible:false})});
      var graph2 = brd4.create('functiongraph', [function(x){return (-a2.Value()/b2.Value())*x+c2.Value()/b2.Value();}, -10, 10], {name:'Line: Equation#2', withLabel:true, strokeColor:'#f44336', strokeWidth:4});
      graph2.label.setAttribute({visible:false});
      graph2.on('over', function () {this.label.setAttribute({visible:true});});
      graph2.on('out', function () {this.label.setAttribute({visible:false})});
      var sol =brd4.create('intersection',[graph1, graph2, 0],{size:4, name:'Solution', withLabel:true, face:'square', fillColor:'yellow', strokeColor:'black', shadow:true});
      var soltext =brd4.create('text', [0, 7, function(){return "[x_0 =" + (sol.X()).toFixed(2) + ', y_0 =' + (sol.Y()).toFixed(2) + ']'}], {visible:false, anchorX:'middle', cssStyle:'fontFamily:Oswald;', color:'red', fontSize:function(){return Math.round(22*brd4.canvasWidth/800.)},fixed:true});
      brd4.create('text', [0, 7, 'This System has No Solution'], {visible:function(){if(a1.Value()/a2.Value()== b1.Value()/b2.Value()){return true} else{return false}}, display:'internal', anchorX:'middle', cssStyle:'fontFamily:Oswald;', color:'red', fontSize:function(){return Math.round(22*brd4.canvasWidth/800.)},fixed:true});
      sol.label.setAttribute({visible:false});
      sol.on('over', function () {this.label.setAttribute({visible:true});
      soltext.setAttribute({visible:true});
      graph1.label.setAttribute({visible:false});
      graph2.label.setAttribute({visible:false});});
      sol.on('out', function () {this.label.setAttribute({visible:false});
    soltext.setAttribute({visible:false});});
  }
}
export default Boxes;
